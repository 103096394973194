if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) {
    var subjectString = this.toString()

    if (position === undefined || position > subjectString.length) {
      position = subjectString.length
    }

    position -= searchString.length
    var lastIndex = subjectString.indexOf(searchString, position)
    return lastIndex !== -1 && lastIndex === position
  }
}


/**
 * Start any application specific calls to via ready to avoid
 * boiler place throughout the core code.
 *
 * Params:
 *	event
 */
jQuery(document).ready(function(e) {
	// prevent caching of ajax GET requests in IE
	$.ajaxSetup({
		cache: false
	});


	DO.Fire('app:ready', [], true);


  $('.parallax').parallax({
    bleed: 10,
    speed: 0.75
  });
});
