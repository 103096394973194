DO.Subscribe(['app:ready'], function (e, $) {
  /**
   * Standard outbound and external file count tracking component.
   *
   */
  var isExternal = function (link) {
    if (typeof link === 'undefined') return false
    var domain = function (url) {
      return url.replace('http://', '').replace('https://', '').split('/')[0]
    }

    var linkDomain = domain(link)

    if (linkDomain.length === 0) {
      // onsite
      return false
    }

    return domain(location.href) !== linkDomain
  }

  var isFile = function (link) {
    if (typeof link === 'undefined') return false
    return link.split('/').pop().split('.').length > 1
  }

  var isMailto = function (link) {
    if (typeof link === 'undefined') return false
    return (link.indexOf('mailto:') > -1)
  }

  $('body').on('click touchend', function (e) {
    // abandon if link already aborted or analytics is not available
    if (typeof ga !== 'function') {
      return
    }

    var link = $(e.target).closest('a')

    if ($(e.target).hasClass('track-ga') || $(e.target).attr('data-ga-category')) {
      // a non link (such as div)
      link = $(e.target)
    } else if (link.length < 1) {
      // no link found
      return
    } else if (!isFile(link.attr('href')) && !isExternal(link.attr('href')) && !$(link).hasClass('track-ga') && !$(link).attr('data-ga-category')) {
      // don't track on site clicks
      return
    } else if ($(link).attr('data-ga-ignore')) {
      // don't track on purposefully ignored
      // used on iframe load links where the src ends with eg .html
      return
    }

    var href = link[0].href,
      category,
      action = window.location.href,
      value = 0

    if (typeof href != 'undefined') {
      e.preventDefault()
    }

    if (link.data('ga-category')) {
      category = link.data('ga-category')
    } else if (isMailto(href)) {
      category = 'Mailto'
    } else if (isExternal(href)) {
      category = 'Outbound'
    } else if (typeof href !== 'undefined' && (href.toLowerCase().endsWith('.pdf') || href.toLowerCase().endsWith('.doc'))) {
      category = 'Download'
    } else if (isFile(href)) {
      category = 'Download'
    } else {
      category = 'Internal'
    }

    if (link.data('ga-action')) {
      action = link.data('ga-action')
    } else if (category == 'Download') {
      action = href
    }

    if (link.data('ga-label')) {
      label = link.data('ga-label')
    } else {
      label = href
    }

    if (link.data('ga-value')) {
      value = link.data('ga-value')
    }

    var loadPage = function () {
      if (!link.data('ga-onpage') && typeof href != 'undefined') {
        if (link.attr('target') == '_blank') {
          window.open(href, '_blank')
        } else {
          document.location = href
        }
      }
    }

    if(typeof dataLayer !== "undefined") {
      dataLayer.push({
        'hitType': 'event',
        'eventCategory': category,
        'eventAction': action,
        'eventLabel': label,
        'eventValue': value,
        'hitCallback': loadPage,
        'transport': 'beacon'
      })
    }

    ga('send', {
      'hitType': 'event',
      'eventCategory': category,
      'eventAction': action,
      'eventLabel': label,
      'eventValue': value,
      'hitCallback': loadPage,
      'transport': 'beacon'
    })
  })

  /**
   * Tab change
   *
   */
  $('body').on('click.trackGaTabChange', '[role=tab]', function () {
    if (!$(this).data('gatracked')) {
      if (typeof ga !== 'undefined') {
        var label = $(this).text()

        ga('send', {
          'hitType': 'event',
          'eventCategory': 'Tab Change',
          'eventAction': 'Changed',
          'transport': 'beacon',
          'eventLabel': window.location.href + ':  ' + label
        })

        $(this).data('gatracked', true)
      }
    }
  })

  /**
   * Form engagement.
   *
   */
  $('body').on('focus.trackGaChange', 'input, textarea, select', function () {
    var form = $(this).parents('form')

    if (!form.data('tracked')) {
      if (typeof ga !== 'undefined') {
        ga('send', {
          'hitType': 'event',
          'eventCategory': 'Form Engagement',
          'eventAction': 'started',
          'transport': 'beacon',
          'eventLabel': window.location.href + ': ' + form.attr('ID')
        })
      }

      form.data('tracked', true)
    }

    if (!$(this).data('gatracked')) {
      if (typeof ga !== 'undefined') {
        var label = form.find('[for=' + $(this).attr('ID') + ']').text()

        if (!label) {
          label = $(this).parents('.field').find('label').first().text()
        }

        ga('send', {
          'hitType': 'event',
          'eventCategory': 'Form Engagement',
          'eventAction': 'changed',
          'transport': 'beacon',
          'eventLabel': window.location.href + ': ' + form.attr('ID') + ' ' + label
        })

        $(this).data('gatracked', true)
      }
    }
  })

  $('body').on('click.trackGaButtonClick', '.button', function (e) {
    var button = $(this)

    if (!button.data('tracked')) {
      if (typeof ga !== 'undefined') {
        var loadPage = function () {
          document.location = href
        }

        setTimeout(loadPage, 1000)

        var category, action

        if ($(this).data('ga-category')) {
          category = $(this).data('ga-category')
        } else {
          category = 'Button'
        }

        if ($(this).data('ga-action')) {
          action = $(this).data('ga-action')
        } else {
          action = 'click'
        }

        ga('send', {
          'hitType': 'event',
          'eventCategory': category,
          'eventAction': action,
          'transport': 'beacon',
          'eventLabel': window.location.href + ': ' + button.attr('name'),
          'hitCallback': loadPage
        })
      }

      button.data('tracked', true)
    }
  })

  $('body').on('submit.trackGaFormSubmit', 'form', function () {
    var form = $(this)

    if (!form.data('tracked')) {
      if (typeof ga !== 'undefined') {
        ga('send', {
          'hitType': 'event',
          'eventCategory': 'Form Engagement',
          'eventAction': 'started',
          'transport': 'beacon',
          'eventLabel': window.location.href + ': ' + form.attr('ID')
        })
      }

      form.data('tracked', true)
    }

    if ($(this).data('submitted')) {
      return
    }

    if (form.get(0).checkValidity() === true) {
      if (typeof ga !== 'undefined') {
        $(this).data('submitted', true)

        ga('send', 'event', 'Form Engagement', 'Submitted', window.location.href + ': ' + form.attr('ID'), {
          transport: 'beacon'
        })
      }
    }
  })
})

DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	$('.article-list').masonry({
		itemSelector: '.article-item',
		percentPosition: true
	});

});
DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	$('body').on('click', '.pure-button--more', function() {
		e.preventDefault();
		var self = $(this),
			url = $(this).data('url');

		$.ajax({
			url: url,
			dataType: 'html',
			complete: function(xhr) {
				var html = $(xhr.responseText);

				var items = html.find('.article-item');
				if(items.length) {
					$('.article-list').append(items)
						.masonry('appended', items);
					var button = html.find('.pure-button--more');
					if(button.length) {
						self.replaceWith(button);
					} else {
						self.fadeOut();
					}
				} else {
					self.fadeOut();
				}
			}
		});

	});

});
DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	$('body').on('click', '.header-search-trigger', function(e) {
		$(this).closest('.header-actions').toggleClass('header-actions--open');
	});

});
DO.Subscribe('app:ready', function(e, $) {

	$('.map').each(function(i, el) {

		if(typeof google === "undefined") return;

		var lat = $(this).data('lat');
		var lng = $(this).data('lng');

		var map;
		var mapOptions = {
			center: new google.maps.LatLng(lat,lng),
			zoom: 17,
			zoomControl: true,
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.DEFAULT
			},
			disableDoubleClickZoom: true,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
			},
			scaleControl: true,
			scrollwheel: true,
			panControl: true,
			streetViewControl: false,
			draggable: true,
			overviewMapControl: false,
			overviewMapControlOptions: {
				opened: false
			},
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles:[
						{
								"featureType": "administrative",
								"elementType": "labels",
								"stylers": [
										{
												"visibility": "on"
										},
										{
												"gamma": "1.82"
										}
								]
						},
						{
								"featureType": "administrative",
								"elementType": "labels.text.fill",
								"stylers": [
										{
												"visibility": "on"
										},
										{
												"gamma": "1.96"
										},
										{
												"lightness": "-9"
										}
								]
						},
						{
								"featureType": "administrative",
								"elementType": "labels.text.stroke",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "landscape",
								"elementType": "all",
								"stylers": [
										{
												"visibility": "on"
										},
										{
												"lightness": "25"
										},
										{
												"gamma": "1.00"
										},
										{
												"saturation": "-100"
										}
								]
						},
						{
								"featureType": "poi.business",
								"elementType": "all",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "poi.park",
								"elementType": "all",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "road",
								"elementType": "geometry.stroke",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "road",
								"elementType": "labels.icon",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "road.highway",
								"elementType": "geometry",
								"stylers": [
										{
												"hue": "#ffaa00"
										},
										{
												"saturation": "-43"
										},
										{
												"visibility": "on"
										}
								]
						},
						{
								"featureType": "road.highway",
								"elementType": "geometry.stroke",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "road.highway",
								"elementType": "labels",
								"stylers": [
										{
												"visibility": "simplified"
										},
										{
												"hue": "#ffaa00"
										},
										{
												"saturation": "-70"
										}
								]
						},
						{
								"featureType": "road.highway.controlled_access",
								"elementType": "labels",
								"stylers": [
										{
												"visibility": "on"
										}
								]
						},
						{
								"featureType": "road.arterial",
								"elementType": "all",
								"stylers": [
										{
												"visibility": "on"
										},
										{
												"saturation": "-100"
										},
										{
												"lightness": "30"
										}
								]
						},
						{
								"featureType": "road.local",
								"elementType": "all",
								"stylers": [
										{
												"saturation": "-100"
										},
										{
												"lightness": "40"
										},
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "transit.station.airport",
								"elementType": "geometry.fill",
								"stylers": [
										{
												"visibility": "on"
										},
										{
												"gamma": "0.80"
										}
								]
						},
						{
								"featureType": "water",
								"elementType": "all",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						}]
		}

		var icon = {
			url: ($('html').hasClass('no-svg')) ? '/themes/default/images/marker_blue.png' : '/themes/default/images/marker_blue.svg',
			size: new google.maps.Size(39,66),
			origin: new google.maps.Point(0,0),
			scaledSize: new google.maps.Size(20,33)
		};

		var mapElement = document.getElementById('map');
		var map = new google.maps.Map(mapElement, mapOptions);

		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(lat, lng),
			icon: icon,
			map: map
		});

	});
});
DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	$('.quicklinks-items').masonry({
		// columnWidth: '.quicklinks-item',
		itemSelector: '.quicklinks-item',
		percentPosition: true
	});

});
// SITEMAP
DO.Subscribe('app:ready', function(e, $) {
	"use strict";


	function init() {
		attachEvents();
	}

	function attachEvents() {
		$('.sitemap').on('click', '.button', function() {
			var self = $(this),
				target = $(self.attr('data-target'));

			// only do an ajax request if the content isn't loaded
			if(target.html().length === 0) {
				requestAjax(target, self);
			}

			toggleChildren(target, self);

			return false;
		});
	}

	function requestAjax(target, self) {
		self.addClass('loading');

		$.ajax({
			url: self.attr('href'),
			data: { ajax: true }
		}).done(function(data) {
			target.html(data);
			self.removeClass('loading');
		});
	}


	function toggleChildren(target, self) {
		self.toggleClass('open');

		if(self.hasClass('open')) {
			target.removeClass('collapse').addClass('collapsed');
			self.attr('aria-expanded', 'true');
			self.children('.linkText').replaceWith('<span class="linkText">Collapse section</span>');
		} else {
			target.removeClass('collapsed').addClass('collapse');
			self.attr('aria-expanded', 'false');
			self.children('.linkText').replaceWith('<span class="linkText">Expand section</span>');
		}
	}

	init();
});

/**
* Slick slider init
*
*/
DO.Subscribe('app:ready', function(e, $) {

	"use strict";

	function init() {
		setup($(document));
	}


	function setup(scope) {
		if(scope.find('[data-slider]').length < 1) {
			return;
		}

		scope.find('[data-slider]').slick(getConfig());

		attachEvents(scope);
	}

	function attachEvents(scope) {
		if(scope.is(document)) {
			$(document).on('ajaxpages:contentloaded', function(e, data) {
				setup(data.target);
			});
		}
	}

	//[0, 568, 768, 1024, 1280]
	function getConfig() {
		return {
			mobileFirst: true,
			variableWidth: true,
			infinite: false,
			slidesToShow: 1,
			arrows: true,
			prevArrow: '<button type="button" class="pure-button pure-button-icononly slick-prev"><span class="sr-only">Previous</span></button>',
			nextArrow: '<button type="button" class="pure-button pure-button-icononly slick-next"><span class="sr-only">Next</span></button>',
			responsive: [{
				breakpoint: 1280,
				settings: {
					slidesToShow: 4,
				}
			},{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			}, {
				breakpoint: 600,
				settings: {
					slidesToShow: 3
				}
			}, {
				breakpoint: 480,
				settings: {
					arrows: true,
					slidesToShow: 2
				}
			}]
		};
	}

	init();

});

/**
 * Toggles and switches extra js (for accessibility). 
 * Uses ../lib/toggles-switches.js from https://github.com/dsurgeons/Toggles-Switches
 */

 DO.Subscribe('app:ready', function(e, $) {

	"use strict";

	var focusable = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
		focusableNative = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed';

	/**
	 * Initialize Button actions
	 */
	function init() {
		setup($(document));
	}

	/**
	 * Apply setup to our newly loaded area (document, or scope)
	 *
	 * @param scope | DOMObject - the area of the page we are applying this functionality to
	 */
	function setup(scope) {
		// if aria hidden, we shouldn't be able to focus
		scope.find('[aria-hidden="true"]').find(focusable).attr('tabindex', '-1');

		attachEvents(scope);
	}


	/**
	 * If an element has the attribute data-toggle-aria we want to toggle the
	 * aria-hidden attribute of the data-toggle-aria value and the tabindex of
	 * all focusable children.
	 * @param scope | DOMObject
	 */
	function attachAriaHide(scope) {
		var targets = scope.find("[data-toggle-aria]"),
			i;

		// Set aria hidden and tabindex -1
		targets.on('click', function() {
			var target = $(this).data('toggle-aria'),
				value = $(target).attr('aria-hidden') == "false" ? "true" : "false",
				tabindex = value == "false" ? "0" : "-1";

			$(target).attr('aria-hidden', value);
			$(target).find(focusable).attr('tabindex', tabindex);
		});
	}

	/**
	 * Attach events
	 * @param scope | DOMObject
	 */
	function attachEvents(scope) {
		attachAriaHide(scope);

		scope.find('[data-contain]').on('click', function(e) {
			e.stopPropagation();
		});

		//make sure we get the order of our events right
		setTimeout(function(){
			keyboardFocus(scope);
		}, 1000);

		if(scope.is(document)) {

			$(document).on('ajaxpages:contentloaded', function(e, data) {
				setup(data.target);
			});
		}
	}

	/**
	 * Allow a focus state that only appears for people using keyboards to navigate
	 * @param scope | DOMObject
	 */
	function keyboardFocus(scope) {
		scope.find(focusable).on('keyup',function(e) {
			$(e.target).addClass("hasfocus");

			if($(e.target).is(focusableNative)){
				return;
			}

			// trigger click if this element doesn't usually support click
			if(e.keyCode == 13) {
				e.preventDefault();
				e.stopPropagation();
				$(e.target).trigger('click');
			}

		}).on('blur focusout',function(e) {
			$(e.target).removeClass("hasfocus");
		});
	}

	init();

});
